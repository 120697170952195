<template>
  <div class="home">
    <h2 class="trajan title">
      <span>Theurgia LLC</span>
    </h2>
    <div class="copyright">
      &copy; MMXXII. All rights reserved.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {

  }
}
</script>

<style>
  .home {
    width: 100%;
    text-align: center;
  }
  .title {
    margin-bottom: 0.25em;
  }
  .title > span {
    font-size: 1.5em;
    background-color: black;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(225,225,225,0.3);
    -webkit-background-clip: text;
       -moz-background-clip: text;
            background-clip: text;
  }
  .copyright {
    font-size: 12px;
  }
</style>
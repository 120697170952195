<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <div id="router-wrapper">
    <router-view/>
  </div>
</template>

<style>
body {
  background-image: linear-gradient(
    50deg,
    hsl(0deg 100% 90%) 0%,
    hsl(6deg 100% 88%) 11%,
    hsl(12deg 100% 85%) 22%,
    hsl(18deg 100% 83%) 33%,
    hsl(23deg 100% 79%) 44%,
    hsl(28deg 100% 76%) 56%,
    hsl(32deg 100% 73%) 67%,
    hsl(36deg 100% 69%) 78%,
    hsl(40deg 99% 65%) 89%,
    hsl(46deg 87% 59%) 100%
  );
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  color: rgb(75,75,75);
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
a {
  color: black;
}
nav {
  /* hide nav for now */
  display: none;

  position: absolute;
  height: 30px;
  padding: 5px;
  top: 0;
  left: 0;
}
#app {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
#router-wrapper {
  padding-top: 50px;
  width: 100%;
}

.trajan {
  font-family: trajan-pro-3,serif;
  font-weight: 300;
  font-style: normal;
}
</style>
